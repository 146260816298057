@import "../Breakpoints";
@import "../Fonts";
@import "../Colors";

html {
  font-size: 16px;
  line-height: 24px;
}

body {
  color: $black;
  background: $warmgrey;
  font-family: $monument;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq($from: desktop_xl) {
    font-size: 1.125rem; // 18px
    line-height: 1.625rem; // 26px
  }
}

// HEADINGS

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

h1 {
  font-size: 2.5rem; // 40px
  line-height: 3rem; // 48px
  margin: 1rem 0 0.75rem;

  @include mq($from: mobile) {
    font-size: 2.75rem; // 44px
    line-height: 3.25rem; // 52px
  }

  @include mq($from: tablet) {
    font-size: 2.875rem; // 46px
    line-height: 3.375rem; // 54px
    margin: 20px 0;
  }

  @include mq($from: laptop) {
    font-size: 3rem; // 48px
    line-height: 3.5rem; // 56px
  }

  @include mq($from: desktop) {
    font-size: 3.125rem; // 50px
    line-height: 3.625rem; // 58px
  }

  @include mq($from: desktop_xl) {
    font-size: 3.25rem; // 52px
    line-height: 3.75rem; // 60px
  }
}

h2 {
  font-size: 2rem; // 32px
  line-height: 2.5rem; // 40px
  margin: 1rem 0 0.75rem;

  @include mq($from: mobile) {
    font-size: 2.25rem; // 36px
    line-height: 2.75rem; // 44px
  }

  @include mq($from: tablet) {
    font-size: 2.375rem; // 38px
    line-height: 2.875rem; // 46px
    margin: 20px 0;
  }

  @include mq($from: laptop) {
    font-size: 2.5rem; // 40px
    line-height: 3rem; // 48px
  }

  @include mq($from: desktop) {
    font-size: 2.625rem; // 42px
    line-height: 3.125rem; // 50px
  }

  @include mq($from: desktop_xl) {
    font-size: 2.75rem; // 44px
    line-height: 3.25rem; // 52px
  }
}

h3 {
  font-size: 1.5rem; // 24px
  line-height: 2rem; // 32px
  margin: 1rem 0 0.75rem;

  @include mq($from: mobile) {
    font-size: 1.75rem; // 28px
    line-height: 2.25rem; // 36px
  }

  @include mq($from: tablet) {
    font-size: 1.875rem; // 30px
    line-height: 2.375rem; // 38px
    margin: 20px 0 16px;
  }

  @include mq($from: laptop) {
    font-size: 2rem; // 32px
    line-height: 2.5rem; // 40px
  }

  @include mq($from: desktop) {
    font-size: 2.125rem; // 34px
    line-height: 2.625rem; // 42px
  }

  @include mq($from: desktop_xl) {
    font-size: 2.25rem; // 36px
    line-height: 2.75rem; // 44px
  }
}

h4 {
  font-size: 1.25rem; // 20px
  line-height: 1.75rem; // 28px
  margin: 20px 0 12px;

  @include mq($from: mobile) {
    font-size: 1.5rem; // 24px
    line-height: 2rem; // 32px
  }

  @include mq($from: desktop) {
    font-size: 1.625rem; // 26px
    line-height: 2.125rem; // 34px
  }

  @include mq($from: desktop_xl) {
    font-size: 1.75rem; // 28px
    line-height: 2.25rem; // 36px
  }
}

h5 {
  font-size: 1.125rem; // 18px
  line-height: 1.75rem; // 28px
  margin: 20px 0 32px;

  @include mq($from: desktop_xl) {
    font-size: 1.25rem; // 20px
    line-height: 1.875rem; // 30px
  }
}

h6 {
  font-family: $adieu;
  font-size: 0.75rem; // 12px
  line-height: 1rem; // 16px
  letter-spacing: 1px;
  font-weight: normal;
  text-transform: uppercase;

  @include mq($from: desktop_xl) {
    font-size: 0.8125rem; // 13px
    line-height: 1.0625rem; // 17px
  }
}

// CAPTION

caption,
.caption {
  font-size: 0.875rem; // 14px
  line-height: 1.25rem; // 20px

  @include mq($from: desktop_xl) {
    font-size: 1rem; // 16px
    line-height: 1.375rem; // 22px
  }
}

// PARAGRAPH

p {
  margin-bottom: 26px;
  font-size: 1rem; // 16px
  line-height: 1.5rem; // 24px
}

// LINKS

a {
  transition: border-color 0.1s ease;
}

a:link,
a:visited {
  border-bottom: 1px solid #000;
  color: #000;
  background: inherit;
  text-decoration: none;

  h6 &,
  h6 {
    padding-bottom: 2px;
  }

  &:hover,
  &:focus {
    border-color: transparent;
  }
}
// stylelint-disable selector-no-qualifying-type
a:link[data-smalls-link],
a:visited[data-smalls-link] {
  border-bottom: 0;
  text-decoration: none;
}
