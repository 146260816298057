// Primary colors

$black: #000;
$white: #fff;
$yellow: #ffe95a;

// Canvas colors

$beef: #f8a98d;
$beige: #c89f84;
$duck: #c5d6a5;
$fish: #8caad0;
$greige: #e1d9c9;
$lightblue: #c5d4e9;
$sage: #96a9a5;
$turkey: #f8a06a;
$warmgrey: #f0ece5;
$warmgrey50: #f7f5f1;
$warmgrey80: #faf8f3;
$warmgrey90: #e5e5e5;

// Text colors

$black72: #434240;
$catnip: #345c19;
$darkgrey: #808080;
$red: #e84622;

// UI Lines

$black6: #cac6c0;
$black7: #a49f97;
$black8: #ddd9d3;
