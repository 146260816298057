body {
  min-height: 100vh;
  max-width: 100%;
}

html {
  scroll-behavior: smooth;
}

.modal-open {
  overflow: hidden;
}
